var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"sign-method"},[_c('div',{staticClass:"t-w"},[_c('step',{attrs:{"s":2}})],1),_c('react-table',{ref:"listTable",attrs:{"table-data":_vm.tableData,"total-items":_vm.totalItems,"actions-type":_vm.actionsType,"hide-row-selection":true,"hide-pagination":true,"columns":_vm.columns,"min-height":100,"current-page":_vm.pageNumber,"scroll":{ x: 0 }}},[_c('div',{staticClass:"t-head"},[_c('span',[_vm._v("签署设置")]),_c('span',[_c('a-button',{attrs:{"size":"default","type":'primary'},on:{"click":function($event){return _vm.createSignType(null)}}},[_vm._v(" "+_vm._s(_vm.l("添加签署方式"))+" ")])],1)])]),_c('div',{staticClass:"b-w"},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"default"},on:{"click":_vm.goBack}},[_vm._v(_vm._s(_vm.l("上一步")))]),_c('a-button',{attrs:{"size":"default","type":"primary"},on:{"click":_vm._agreementTemplateAsyncElectronicSignService}},[_vm._v(" "+_vm._s(_vm.l("下一步"))+" ")])],1),_c('a-modal',{attrs:{"centered":"","title":_vm.inputModalParams.title,"visible":_vm.inputModalParams.visible,"confirm-loading":_vm.inputModalParams.confirmLoading,"cancelText":_vm.inputModalParams.cancelText,"okText":_vm.inputModalParams.okText,"maskClosable":_vm.inputModalParams.maskClosable,"destroyOnClose":_vm.inputModalParams.destroyOnClose,"width":_vm.inputModalParams.width},on:{"ok":_vm.inputModalParams.confirm,"cancel":_vm.inputModalParams.cancel}},[_c('div',[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":"签署人类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'signerType',
                { rules: [{ required: true, message: '请选择类型' }] } ]),expression:"[\n                'signerType',\n                { rules: [{ required: true, message: '请选择类型' }] },\n              ]"}],attrs:{"placeholder":"请选择"},on:{"change":_vm.signTypeChange}},_vm._l((_vm.inputModalParams.signerTypeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"自动签署","required":""}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'signMethod',
                { valuePropName: 'checked', initialValue: '0' } ]),expression:"[\n                'signMethod',\n                { valuePropName: 'checked', initialValue: '0' },\n              ]"}],attrs:{"disabled":_vm.inputModalParams.switchDisabled,"button-style":"solid"}},[_c('a-radio-button',{attrs:{"value":0}},[_vm._v(" 手动签署 ")]),_c('a-radio-button',{attrs:{"value":1}},[_vm._v(" 自动签署 ")]),_c('a-radio-button',{attrs:{"value":2}},[_vm._v(" 手动触发自动签署")])],1)],1),_c('a-form-item',{attrs:{"label":"协议内容通用字段"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'generalFieldsOfAgreementContentArr',
                {
                  initialValue:
                    _vm.inputModalParams.generalFieldsOfAgreementContentInitVal,
                  rules: [
                    { type: 'array', required: true, message: '请选择' } ],
                } ]),expression:"[\n                'generalFieldsOfAgreementContentArr',\n                {\n                  initialValue:\n                    inputModalParams.generalFieldsOfAgreementContentInitVal,\n                  rules: [\n                    { type: 'array', required: true, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请选择","options":_vm.inputModalParams.generalFieldsOfAgreementContentList}})],1),_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'sort',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    } ],
                } ]),expression:"[\n                'sort',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }